exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-about-us-tsx": () => import("./../../../src/pages/about-us.tsx" /* webpackChunkName: "component---src-pages-about-us-tsx" */),
  "component---src-pages-blog-tsx": () => import("./../../../src/pages/blog.tsx" /* webpackChunkName: "component---src-pages-blog-tsx" */),
  "component---src-pages-cloud-services-distributed-cloud-tsx": () => import("./../../../src/pages/cloud-services/distributed-cloud.tsx" /* webpackChunkName: "component---src-pages-cloud-services-distributed-cloud-tsx" */),
  "component---src-pages-cloud-services-index-tsx": () => import("./../../../src/pages/cloud-services/index.tsx" /* webpackChunkName: "component---src-pages-cloud-services-index-tsx" */),
  "component---src-pages-cloud-services-managed-365-tsx": () => import("./../../../src/pages/cloud-services/managed-365.tsx" /* webpackChunkName: "component---src-pages-cloud-services-managed-365-tsx" */),
  "component---src-pages-cloud-services-managed-cloud-hosting-tsx": () => import("./../../../src/pages/cloud-services/managed-cloud-hosting.tsx" /* webpackChunkName: "component---src-pages-cloud-services-managed-cloud-hosting-tsx" */),
  "component---src-pages-cloud-services-self-managed-cloud-hosting-tsx": () => import("./../../../src/pages/cloud-services/self-managed-cloud-hosting.tsx" /* webpackChunkName: "component---src-pages-cloud-services-self-managed-cloud-hosting-tsx" */),
  "component---src-pages-cloud-services-self-managed-cloud-services-tsx": () => import("./../../../src/pages/cloud-services/self-managed-cloud-services.tsx" /* webpackChunkName: "component---src-pages-cloud-services-self-managed-cloud-services-tsx" */),
  "component---src-pages-contact-tsx": () => import("./../../../src/pages/contact.tsx" /* webpackChunkName: "component---src-pages-contact-tsx" */),
  "component---src-pages-dmbs-tsx": () => import("./../../../src/pages/dmbs.tsx" /* webpackChunkName: "component---src-pages-dmbs-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-solutions-tsx": () => import("./../../../src/pages/solutions.tsx" /* webpackChunkName: "component---src-pages-solutions-tsx" */),
  "component---src-pages-web-services-dns-services-tsx": () => import("./../../../src/pages/web-services/dns-services.tsx" /* webpackChunkName: "component---src-pages-web-services-dns-services-tsx" */),
  "component---src-pages-web-services-domain-management-tsx": () => import("./../../../src/pages/web-services/domain-management.tsx" /* webpackChunkName: "component---src-pages-web-services-domain-management-tsx" */),
  "component---src-pages-web-services-index-tsx": () => import("./../../../src/pages/web-services/index.tsx" /* webpackChunkName: "component---src-pages-web-services-index-tsx" */),
  "component---src-pages-web-services-ssl-management-tsx": () => import("./../../../src/pages/web-services/ssl-management.tsx" /* webpackChunkName: "component---src-pages-web-services-ssl-management-tsx" */),
  "component---src-views-blog-post-view-index-tsx": () => import("./../../../src/views/BlogPostView/index.tsx" /* webpackChunkName: "component---src-views-blog-post-view-index-tsx" */),
  "component---src-views-simple-text-view-index-tsx": () => import("./../../../src/views/SimpleTextView/index.tsx" /* webpackChunkName: "component---src-views-simple-text-view-index-tsx" */)
}

